import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/tickets',
            name: 'tickets',
            component: () => import("./tickets.vue")
        },
        {
            path: '/ticketsByEvent/:eventIdentifier',
            name: 'ticketsByEvent',
            component: () => import("./ticketsByEvent.vue")
        },
    ];
};