
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model'

@Options({})
export default class ConfirmModal extends Vue {

    @Prop() title: string;
    @Prop() text: string;
    @Prop({
        default: 'OK'
    }) okText: string;
    @Prop({
        default: 'Annulla'
    }) koText: string;
    @Prop() okCallback: () => Promise<any>;
    @Prop() koCallback: () => Promise<any>;

    async ok(){
        if(this.okCallback){
            await this.okCallback();
        }
        this.$emit('close');
    }

    async ko(){
        if(this.koCallback){
            await this.koCallback();
        }
        this.$emit('close');
    }

}
