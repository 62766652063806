import * as OM from '@/Model';

class _StorageServices {
    
    private readFromLocalStorage<T>(lsName: string): T | null {
        let data = window.localStorage.getItem(lsName);
        if(data){
            return JSON.parse(data);
        } else {
            return null;
        }
    }
    
    getOpTableFilters(name: string) {
        return this.readFromLocalStorage<any>(name);
    }
    setOpTableFilters(name: string, value: any){
        window.localStorage.setItem(name, JSON.stringify(value));
    }

}

export let StorageServices = new _StorageServices();