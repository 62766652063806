
export let date = function(el, binding, vnode, prevVnode){
    if(!binding.value)
        return;

    if(el.tagName.toLowerCase() != 'input'){
        console.error('v-date directive must be used on input tags. use type="date"');
        return;
    }
    if(el.getAttribute('type') != 'date' && el.getAttribute('type') != 'datetime-local'){
        console.error('v-date directive must be used on input with type="date"');
        return;
    }

    let val = binding.value;
    if(val){
        (<HTMLInputElement>el).value = formatDateForInput(val, el.getAttribute('type') == 'datetime-local');
    }
}

function formatDateForInput(inputDate: string, isDateTimeLocal){
    var stringDate = inputDate.toString();
    let date = new Date(stringDate);
    if(isValidDate(date)){
        let years = date.getFullYear().toString();
        while(years.length < 4){
            years = "0" + years;
        } 
        let months = (date.getMonth() + 1).toString();
        if(months.length < 2) months = "0" + months;
        let days = date.getDate().toString();
        if(days.length < 2) days = "0" + days;

        let ris = years +  "-" + months + "-" + days;

        if(isDateTimeLocal){   
            let hours = date.getHours().toString();
            if(hours.length < 2) hours = "0" + hours;
            let minutes = date.getMinutes().toString();
            if(minutes.length < 2) minutes = "0" + minutes;
            ris += "T" + hours + ":" + minutes;
        }
        return ris;
    }
}

function isValidDate(d: any) {
    return d instanceof Date && !isNaN(<any>d);
}