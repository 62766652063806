
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel'

@Options({})
export default class datetimeLocal extends mixins(WithModel) {

    onModelValueChange(next, prev){
    }

    emitIsoDate(ev){
        try {
            let toEmit = new Date(ev.target.value).toISOString();
            this.emitModel(toEmit);
        } catch(ex){
            this.emitModel(null);
        }
    }

}
