import { StorageServices } from '@/services/StorageServices';
import { createStore } from 'vuex'
import * as OM from '@/Model';

let vuestore = {
    state: {
        showSpinner: 0,
        beforeNavCbs: [],
        afterNavCbs: []
    }
}

let store = createStore(vuestore);
export default store