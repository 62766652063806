import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import modalPlugin from './plugins/modalPlugin';
import * as DIRECTIVES from '@/directives';
import FILTERS from '@/filters';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/css/style.css';

//toastr
import 'toastr/build/toastr.min.css';
import toastr from 'toastr';

toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 4000,
    extendedTimeOut: 1000,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
};

import moment from 'moment';
moment.locale("IT-it");

const app = createApp(App)
    .use(store)
    .use(router)
    .use(modalPlugin)
    .directive('date', DIRECTIVES.date)

app.config.globalProperties.$filters = FILTERS;

const ComponentContext = require.context('./components', true, /\.vue$/i);
ComponentContext.keys().forEach(fileName => {
    var component = ComponentContext(fileName);
    let componentName = fileName.split('/').pop().split('.')[0];
    app.component(componentName, (<any>component).default || component);
})

app.mount('#app');
