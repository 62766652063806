import * as OM from '@/Model';
import router from '@/router';
import store from '@/store';
import { CommonServices } from './CommonServices';
import { StorageServices } from './StorageServices';

class _LoginServices {
    
    loginCallback = (loginToken: string) => {
        return new Promise<void>((resolve, reject) => {
            CommonServices.setAuthToken(loginToken);
            resolve();
        })
    }

    logout(){
        let prom = new Promise<void>((resolve, reject) => {
            CommonServices.destroyToken();
            resolve();
        })
        return prom;
    }

}

export let LoginServices = new _LoginServices();