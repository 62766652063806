import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/eventi',
            name: 'eventi',
            component: () => import("./eventi.vue")
        },
    ];
};