import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import begin from '@/views/begin';
import eventi from '@/views/eventi';
import ticket from '@/views/ticket';

const routes: Array<RouteRecordRaw> = [
    ...begin.routes(),
    {
        path: '/',
        component: () => import("../views/layout/layout.vue"),
        children: [
            ...eventi.routes(),
            ...ticket.routes(),
        ]
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        window.scrollTo(savedPosition ? savedPosition.left : 0, savedPosition ? savedPosition.top : 0);
    }
})


router.beforeEach((to, from, next) => {
    if(store.state.beforeNavCbs.length == 0)
        next();
    store.state.beforeNavCbs.forEach(x => {
        x(to, from, next);
    });
    store.state.beforeNavCbs = [];
})

router.afterEach((to, from) => {
    store.state.afterNavCbs.forEach(x => {
        x(to, from);
    });
    store.state.afterNavCbs = [];
})

export default router
